
import { defineComponent, ref } from 'vue'
import cabecera from '@/components/cabecera.vue'
import pie from '@/components/pie.vue'
import Swal from 'sweetalert2'

export default defineComponent({
  components: { cabecera,pie },
  name:"Contacto",

  setup(){
const nombre = ref()
const telefono = ref()
const correo = ref()
const mensaje = ref()
const enviar = async () =>{

if(mensaje.value!="" && telefono.value!="" && correo.value!="" && nombre.value!=""){
const parametros = {
  headers: {"content-type":"application/json"},
  body: JSON.stringify({
    nombre:nombre.value,correo:correo.value,mensaje:mensaje.value, telefono:telefono.value
  }),
  method: "post"
}

const consulta = await fetch("https://latamlogistichn.com/m.php?ac=correo",parametros)
const respuesta = await consulta.json() 
const R = respuesta["respuesta"]
if(R=="1"){
Swal.fire({
  position: 'top-end',
  icon: 'success',
  title: 'Mensaje enviado',
  showConfirmButton: false,
  timer: 1500
})
mensaje.value=""
telefono.value=""
correo.value=""
nombre.value=""
}else{
  "Error"
}
}
}

return {
nombre,
telefono,
correo,
mensaje,
enviar,
}
  }
})
