
import { defineComponent } from 'vue'
import cabecera from '@/components/cabecera.vue'
import pie from '@/components/pie.vue'
export default defineComponent({
  components: { cabecera,pie },
  name:"Contacto",

  setup(){

return {

}
  }
})
