
import { defineComponent } from 'vue'

export default defineComponent({
  name:"Contacto",
  setup(){

return {

}
  }
})
