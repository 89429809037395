
import { Options, Vue } from 'vue-class-component';
import Principal from '@/components/Principal.vue'; // @ is an alias to /src

@Options({
  components: {
    Principal,
  },
})
export default class Home extends Vue {}
