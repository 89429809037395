import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Principal from '../views/Principal.vue'
import Contacto from '../views/Contacto.vue'
import QuienesSomos from '../views/QuienesSomos.vue'
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Principal',
    component: Principal
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: Contacto
  },
  {
    path: '/quienessomos',
    name: 'QuienesSomos',
    component: QuienesSomos
  }
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
