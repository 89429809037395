
import  { defineComponent, onMounted, ref } from 'vue'
import cabecera from './cabecera.vue'
import pie from './pie.vue'
declare global {
interface Array<T> {
aleatorio(): string
}
}

export default defineComponent({
  components: { cabecera,pie },
  name:"Principal",
  setup(){
 const clase = ref("barco")



 onMounted(()=>{

Array.prototype.aleatorio = function(){
return this[Math.floor(Math.random()*this.length)];

}
setInterval(()=>{
const array = ["avion","barco","rastra"]

clase.value = array.aleatorio()


},7000)
 })


  
   


  return {
  clase
}
  }
})
